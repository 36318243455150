<script>
import {layoutComputed} from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import {mapGetters} from "vuex";

export default {
    components: {
        Vertical,
        Horizontal,
    },
    data() {
        return {
            pageTitle: ''
        };
    },
    computed: {
        ...layoutComputed,
        ...mapGetters({
            title: 'app/title',
            translations: 'trans/translations'
        }),
    },
    methods: {
        setPageTitle: function () {
            switch (typeof this.title) {
                case 'string':
                    return this.pageTitle = this.title;
                case 'object':
                    if (!this.title.key) {
                        return this.pageTitle = '';
                    }
                    if (!this.title.count) {
                        return this.pageTitle = this.$t(this.title.key).ucFirst();
                    }
                    return this.pageTitle = this.$tc(this.title.key, this.title.count).ucFirst();
                default:
                    return this.pageTitle = '';
            }
        },
    },
    watch: {
        translations: function () {
            this.setPageTitle()
        },
        title: function () {
            this.setPageTitle()
        },
    },
    mounted() {
        this.setPageTitle()
    },
};
</script>

<template>
    <div>
        <Vertical v-if="layoutType === 'vertical'" :layout="layoutType" :pagetitle="pageTitle">
            <router-view></router-view>
        </Vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType" :pagetitle="pageTitle">
            <router-view></router-view>
        </Horizontal>
    </div>
</template>
