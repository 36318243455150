<script>
import {SimpleBar} from "simplebar-vue3";
import {mapGetters} from "vuex";
import router from "../router";

/**
 * Topbar component
 */
export default {

    computed: {
        ...mapGetters({
            locale: 'trans/locale',
            authenticated: 'user/authenticated'
        }),

        letter: function () {
            return this.authenticated && this.authenticated.firstname ? this.authenticated.firstname[0] : '?'
        },

        language: function () {
            return this.languages.find(lang => lang.language === this.locale)
        },
    },

    data() {
        return {
            languages: [
                {
                    flag: require("@/assets/images/flags/us.jpg"),
                    language: "en",
                    title: "English",
                },
                {
                    flag: require("@/assets/images/flags/hungary.jpg"),
                    language: "hu",
                    title: "Hungarian",
                }
            ],
            text: null,
            flag: null,
            value: null,
        };
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        layoutscroll: {
            type: Boolean,
            required: true,
        },
        pagetitle: {
            type: String,
            required: true,
        },
    },
    mounted() {
    },
    methods: {
        router() {
            return router
        },
        selectLocale: function (locale) {
            this.$store.dispatch('trans/locale', locale)
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        logout() {
            if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                this.authLogout().then(() => {
                    this.$router.push(
                        this.$route.query.redirectFrom || {
                            name: "login",
                        }
                    );
                });
            } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
                this.authFakeLogout().then(() => {
                    this.$router.push(
                        this.$route.query.redirectFrom || {
                            name: "login",
                        }
                    );
                });
            }
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        }
    },
    components: {
        SimpleBar,
    },
    created() {
        this.$i18n.locale = this.locale
    },
    watch: {
        type: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "dark":
                            // console.log('dark');
                            document.body.setAttribute("data-topbar", "dark");
                            break;
                        case "light":
                            document.body.setAttribute("data-topbar", "light");
                            document.body.removeAttribute("data-layout-size", "boxed");
                            break;
                        default:
                            document.body.setAttribute("data-topbar", "dark");
                            break;
                    }
                }
            },
        },
        layoutscroll: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case false:
                            document.body.setAttribute("data-layout-scrollable", "false");
                            break;
                        case true:
                            document.body.setAttribute("data-layout-scrollable", "true");
                            break;
                        default:
                            document.body.setAttribute("data-layout-scrollable", "false");
                            break;
                    }
                }
            },
        },
    },
};
</script>

<template>
    <header id="page-topbar" class="isvertical-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link class="logo logo-dark" to="/">
            <span class="logo-sm">
              <img alt="" height="22" src="@/assets/images/logo-dark-sm.png"/>
            </span>
                        <span class="logo-lg">
              <img alt="" height="22" src="@/assets/images/logo-dark-sm.png"/>
            </span>
                    </router-link>

                    <router-link class="logo logo-light" to="/">
            <span class="logo-lg">
              <img alt="" height="22" src="@/assets/images/logo-light.png"/>
            </span>
                        <span class="logo-sm">
              <img alt="" height="22" src="@/assets/images/logo-light-sm.png"/>
            </span>
                    </router-link>
                </div>

                <button
                        class="
            btn btn-sm
            px-3
            font-size-16
            header-item
            vertical-menu-btn
            topnav-hamburger
          "
                        type="button"
                        @click="toggleMenu"
                >
          <span class="hamburger-icon open">
            <span></span>
            <span></span>
            <span></span>
          </span>
                </button>

                <div class="d-none d-sm-block ms-3 align-self-center">
                    <h4 class="page-title">
                        <a @click="router().go(-1)" class="cursor-pointer me-2">
                            <i class="fas fa-long-arrow-alt-left" :title="$t('base.back').ucFirst()"></i>
                        </a>
                        {{ pagetitle }}
                    </h4>
                </div>
            </div>

            <div class="d-flex">
                <div v-if="false" class="dropdown">
                    <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <eva-icon class="icon-sm" name="search-outline"></eva-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0">
                        <form class="p-2">
                            <div class="search-box">
                                <div class="position-relative">
                                    <input
                                            class="form-control bg-light border-0"
                                            placeholder="Search..."
                                            type="text"
                                    />
                                    <eva-icon
                                            class="search-icon"
                                            name="search-outline"
                                    ></eva-icon>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="dropdown d-inline-block language-switch">
                    <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <img
                                alt="Header Language"
                                class="header-lang-img"
                                height="16"
                                :src="language.flag"
                        />
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                        <!-- item-->
                        <a
                                class="dropdown-item notify-item language"
                                data-lang="eng"
                                href="javascript:void(0);"
                                @click="selectLocale('en')"
                        >
                            <img
                                    alt="user-image"
                                    class="me-1"
                                    height="12"
                                    src="@/assets/images/flags/us.jpg"
                            />
                            <span class="align-middle">English</span>
                        </a>
                        <!-- item-->
                        <a
                                class="dropdown-item notify-item language"
                                data-lang="hun"
                                href="javascript:void(0);"
                                @click="selectLocale('hu')"
                        >
                            <img
                                    alt="user-image"
                                    class="me-1"
                                    height="12"
                                    src="@/assets/images/flags/hungary.jpg"
                            />
                            <span class="align-middle">Hungarian</span>
                        </a>
                    </div>
                </div>

                <div v-if="false" class="dropdown d-none d-lg-inline-block">
                    <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item noti-icon"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <eva-icon class="icon-sm" name="grid-outline"></eva-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                        <div class="p-3">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="m-0 font-size-15">Web Apps</h5>
                                </div>
                                <div class="col-auto">
                                    <a
                                            class="small fw-semibold text-decoration-underline"
                                            href="#!"
                                    >
                                        View All</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="px-lg-2 pb-2">
                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img alt="Github" src="@/assets/images/brands/github.png"/>
                                        <span>GitHub</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="bitbucket"
                                                src="@/assets/images/brands/bitbucket.png"
                                        />
                                        <span>Bitbucket</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="dribbble"
                                                src="@/assets/images/brands/dribbble.png"
                                        />
                                        <span>Dribbble</span>
                                    </a>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="dropbox"
                                                src="@/assets/images/brands/dropbox.png"
                                        />
                                        <span>Dropbox</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="mail_chimp"
                                                src="@/assets/images/brands/mail_chimp.png"
                                        />
                                        <span>Mail Chimp</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img alt="slack" src="@/assets/images/brands/slack.png"/>
                                        <span>Slack</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="false" class="dropdown d-inline-block">
                    <button
                            id="page-header-notifications-dropdown-v"
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item noti-icon"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <eva-icon class="icon-sm" name="bell-outline"></eva-icon>
                        <span class="noti-dot bg-danger rounded-pill">4</span>
                    </button>

                    <div
                            aria-labelledby="page-header-notifications-dropdown-v"
                            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    >
                        <div class="p-3">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="m-0 font-size-15">Notifications</h5>
                                </div>
                                <div class="col-auto">
                                    <a
                                            class="small fw-semibold text-decoration-underline"
                                            href="#!"
                                    >
                                        Mark all as read</a
                                    >
                                </div>
                            </div>
                        </div>
                        <SimpleBar data-simplebar style="max-height: 250px">
                            <a class="text-reset notification-item" href="#!">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 me-3">
                                        <img
                                                alt="user-pic"
                                                class="rounded-circle avatar-sm"
                                                src="@/assets/images/users/avatar-3.jpg"
                                        />
                                    </div>
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1">James Lemire</h6>
                                        <div class="font-size-13 text-muted">
                                            <p class="mb-1">It will seem like simplified English.</p>
                                            <p class="mb-0">
                                                <i class="mdi mdi-clock-outline"></i>
                                                <span>1 hour ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item" href="#!">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 avatar-sm me-3">
                    <span
                            class="
                        avatar-title
                        bg-primary
                        rounded-circle
                        font-size-16
                      "
                    >
                      <i class="bx bx-cart"></i>
                    </span>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1">Your order is placed</h6>
                                        <div class="font-size-13 text-muted">
                                            <p class="mb-1">
                                                If several languages coalesce the grammar
                                            </p>
                                            <p class="mb-0">
                                                <i class="mdi mdi-clock-outline"></i>
                                                <span>3 min ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item" href="#!">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 avatar-sm me-3">
                    <span
                            class="
                        avatar-title
                        bg-success
                        rounded-circle
                        font-size-16
                      "
                    >
                      <i class="bx bx-badge-check"></i>
                    </span>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1">Your item is shipped</h6>
                                        <div class="font-size-13 text-muted">
                                            <p class="mb-1">
                                                If several languages coalesce the grammar
                                            </p>
                                            <p class="mb-0">
                                                <i class="mdi mdi-clock-outline"></i>
                                                <span>3 min ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a class="text-reset notification-item" href="#!">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 me-3">
                                        <img
                                                alt="user-pic"
                                                class="rounded-circle avatar-sm"
                                                src="@/assets/images/users/avatar-6.jpg"
                                        />
                                    </div>
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1">Salena Layfield</h6>
                                        <div class="font-size-13 text-muted">
                                            <p class="mb-1">
                                                As a skeptical Cambridge friend of mine occidental.
                                            </p>
                                            <p class="mb-0">
                                                <i class="mdi mdi-clock-outline"></i>
                                                <span>1 hour ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </SimpleBar>
                        <div class="p-2 border-top d-grid">
                            <a
                                    class="btn btn-sm btn-link font-size-14 btn-block text-center"
                                    href="javascript:void(0)"
                            >
                                <i class="uil-arrow-circle-right me-1"></i>
                                <span>View More..</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="false" class="dropdown d-inline-block">
                    <button
                            id="right-bar-toggle-v"
                            class="btn header-item noti-icon right-bar-toggle toggle-right"
                            type="button"
                            @click="toggleRightSidebar"
                    >
                        <eva-icon class="icon-sm toggle-right" name="settings-outline"></eva-icon>
                    </button>
                </div>

                <div  class="dropdown d-inline-block">
                    <button
                            id="page-header-user-dropdown-v"
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item user text-start d-flex align-items-center"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <img
                                v-if="authenticated.image"
                                :src="authenticated.image"
                                :alt="authenticated.name"
                                class="rounded-circle header-profile-user"
                        />
                        <div v-else class="avatar-sm">
                            <span class="avatar-title rounded-circle bg-primary text-white font-size-16"> {{ letter }} </span>
                        </div>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end pt-0">
                        <div class="p-3 border-bottom">
                            <h6 class="mb-0">{{ authenticated.name }}</h6>
                            <p class="mb-0 font-size-11 text-muted">
                                {{ authenticated.email }}
                            </p>
                        </div>
                        <router-link class="dropdown-item" :to="$t('routes./users/profile')"
                        ><i
                                class="
                                  mdi mdi-account-circle
                                  text-muted
                                  font-size-16
                                  align-middle
                                  me-1
                                "
                        ></i>

                            <span class="align-middle">{{ $t('menu_points.profile').ucFirst() }}</span></router-link>
                        <router-link class="dropdown-item" to="/auth/logout"
                        ><i
                                class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
                        ></i>
                            <span class="align-middle">{{ $t('menu_points.logout').ucFirst() }}</span></router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
