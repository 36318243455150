<template>
    <li v-if="show(sub.roles)" :class="{'mm-active': active}" :key="sub.id">
        <a
                v-if="sub.link === '/'"
                :class="{ 'has-arrow': !sub.badge && false, 'has-dropdown': sub.badge }"
                class="is-parent cursor-pointer"
                @click="active = !active"
        >
            <i :class="sub.icon" class="me-2 nav-icon" :title="sub.translation_key ? $t(sub.translation_key).ucFirst() : sub.title.ucFirst()"></i>
            <span class="menu-point-title">{{ sub.translation_key ? $t(sub.translation_key).ucFirst() : sub.title.ucFirst() }}</span>
            <span
                    v-if="sub.badge"
                    :class="`badge rounded-pill bg-${sub.badge.variant} float-end`"
            >{{ $t(sub.badge.text) }}</span>
        </a>
        <router-link
                v-if="sub.link && sub.link !== '/'"
                :to="sub.link"
                class="side-nav-link-ref"
        >
            <i :class="sub.icon" class="me-2 nav-icon" :title="sub.translation_key ? $t(sub.translation_key).ucFirst() : sub.title.ucFirst()"></i>
            <span class="menu-point-title">{{ sub.translation_key ? $t(sub.translation_key).ucFirst() : sub.title.ucFirst() }}</span>
            <span
                    v-if="sub.badge"
                    :class="`badge rounded-pill ${sub.badge.variant} float-end`"
            >{{ $t(sub.badge.text) }}</span
            >
        </router-link>
        <div ref="trans">
            <transition type="animation" @enter="setHeight" @after-leave="height = '100%'">
                <ul v-if="sub.menu_points && active" aria-expanded="false" class="sub-menu mm-collapse" :class="{'mm-show': active}">
                    <template v-for="(subsub, index) of sub.menu_points">
                        <li v-if="show(subsub.roles)" :key="index">
                            <router-link
                                v-if="subsub.link"
                                :to="subsub.link"
                                class="side-nav-link-ref white-space-normal"
                            >
                                <i v-if="subsub.icon" class="fa me-3 nav-icon" :class="subsub.icon" :title="subsub.translation_key ? $t(subsub.translation_key).ucFirst() : subsub.title.ucFirst()"></i>
                                <span class="menu-point-title">{{ subsub.translation_key ? $t(subsub.translation_key).ucFirst() : subsub.title.ucFirst() }}</span>
                            </router-link>
                            <a
                                v-else
                                class="side-nav-link-a-ref has-arrow white-space-normal"
                                href="javascript:void(0);"
                            >
                                <i v-if="subsub.icon" class="fa me-3 nav-icon" :class="subsub.icon" :title="subsub.translation_key ? $t(subsub.translation_key).ucFirst() : subsub.title.ucFirst()"></i>
                                <span class="menu-point-title">{{ subsub.translation_key ? $t(subsub.translation_key).ucFirst() : subsub.title.ucFirst() }}</span></a>
                            <ul
                                v-if="subsub.menu_points"
                                aria-expanded="false"
                                class="sub-menu mm-collapse"
                            >

                                <li
                                    v-for="(subSubsub, index) of subsub.subItems"
                                    :key="index"
                                >
                                    <router-link
                                        :to="subSubsub.link"
                                        class="side-nav-link-ref"

                                    >{{ $t(subSubsub.label) }}
                                    </router-link
                                    >
                                </li>
                            </ul>
                        </li>
                    </template>
                </ul>
            </transition>
        </div>
    </li>
</template>

<script>
export default {
    name: "menu-point",

    props: {
        sub: {}
    },

    data: function () {
        return {
            active: false,
            height: '100%',
        }
    },

    methods: {
        setHeight: function () {
            if (this.height === '100%') {
                this.height = this.$refs.trans.clientHeight + 'px';
            }
        },

        show: function (roles) {
            if (!roles || !roles.length) {
                return true
            }

            return roles.every(role => {
                return this.can(role.key)
            });

        }
    }
}
</script>

<style scoped>
@keyframes slide-left {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.3s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.3s ease-in;
    overflow: hidden;
}
</style>
